import React from 'react';

export const SectionHeader = ({title, subTitle, containerClass}) => {
  return (
    <div className={containerClass ?? 'mb-12'}>
      <h1 className="font-display text-center text-3xl font-medium text-white">
        {title}
      </h1>
      <div className="mx-auto">
        <p className="mt-6 text-lg text-center leading-normal dark:text-jacarta-300">
          {subTitle}
        </p>
      </div>
    </div>
  )
}

import Link from 'next/link';

const CallToActions = ({token, background}) => {
  let calltoActionContent = {
    title: "Interested in creating a Web3 project?",
    background: '/images/cta.png',
    description: "Contact us today to see how our Web3 solution will help you realise your full business potential!",
    btnText: "Start Now",
    link: '/contact'
  };

  if (token) {
    calltoActionContent = {
      title: "Try out our cool Alchemis Token Tool!",
      background: '/images/cta-token.png',
      description: "Discover how specific cryptocurrencies work — and get a bit of each crypto to try out for yourself.\n",
      btnText: "Start Now",
      link: 'https://tokentool.alchemis.com/multi-sender'
    };
  }

  return (
    <div className="container" style={{marginBottom: '-148px'}}>
      <div className="z-10 overflow-hidden rounded-2.5xl px-16 py-24 lg:px-28 relative bg-cover bg-center after:absolute" style={{ backgroundImage: `url(${calltoActionContent.background})`}}>
        <div className="lg:flex lg:justify-between">
          <div className="mb-6 max-w-md lg:mb-0">
            <h2 className="mb-5 font-display text-3xl text-jacarta-700 dark:text-white">
              {calltoActionContent.title}
            </h2>
            <p className="text-lg leading-normal dark:text-jacarta-300">
              {calltoActionContent.description}
            </p>
          </div>
          <Link href={calltoActionContent.link} legacyBehavior>
            <a
              className="inline-block self-center rounded-full bg-primary py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark"
            >
              {calltoActionContent.btnText}
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CallToActions;

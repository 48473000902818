const Hero_10 = () => {
  const heroContent = {
    title: "Exploring new horizon of web3 technology",
    descriptions: `We believe in working collaboratively with our clients to create innovative solutions that unlock the full potential of Web3 to drive business growth.`,
    btnText: "Contact Us",
  };

  return (
    <section className="relative py-20 lg:pt-48">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img src="/images/gradient_dark.jpeg" alt="gradient dark" style={{width: '100%'}}/>
      </picture>

      <div className="container">
        <div className="h-full items-center gap-4 flex flex-col lg:grid lg:grid-cols-12">
          <div className="flex h-full flex-col items-center justify-center py-10 lg:col-span-5 lg:items-start lg:py-20 order-last lg:order-first">
            <h1 className="mb-6 text-center font-display text-5xl text-jacarta-700 dark:text-white lg:text-left lg:text-6xl">
              {heroContent.title}
            </h1>
            <p className="mb-8 max-w-md text-center text-lg dark:text-jacarta-200 lg:text-left">
              {heroContent.descriptions}
            </p>
            <a
              href="/contact"
              className="rounded-full bg-primary py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark"
            >
              {heroContent.btnText}
            </a>
          </div>
          {/* End flex-col */}

          {/* <!-- Hero image --> */}
          <div className="col-span-7">
            <div className="relative text-center order-first lg:order-last">
              <img
                src="/images/home/home-header.png"
                alt="hero"
                style={{height: 'auto', width: 1120}}
                className="inline-block"
              />
              <img
                src="/images/home/header-side.png"
                alt=""
                style={{height: 'auto', width: 1120}}
                className="animate-fly absolute top-0 -z-10"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero_10;

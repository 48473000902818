import FancyBlock from '../../components/crypto/FancyBlock';
import HomeCard from '../../components/home-card/index';
import NeedHelpBlock from '../../components/crypto/nee-help-block';
import Hero_10 from '../../components/hero/hero_10';
import Testimonial from '../../components/testimonial/Testimonial';
import TrustedPartner from '../../components/dao/TrustedPartner';
import { NextSeo } from 'next-seo';
import CallToActions from '@apps/components/ico-landing/CallToActions';
import React from 'react';
import FeaturesCard from '../../components/crypto/features-card';

const Home = () => {
  const metaTitle = 'Home | Alchemis';
  const metaDesc = `.`;
  const metaImg = `/favicon-dark.ico`;
  const featuresList = [
    {
      id: 1,
      icon: '/images/crypto-app/w1.svg',
      title: 'Blockchain Development',
      text: `Blockchain development services: Empowering businesses with secure and decentralized solutions.`
    },
    {
      id: 2,
      icon: '/images/crypto-app/w2.svg',
      title: 'Smart Contracts Development',
      text: `Smart contract development for ICOs, DeFi, and dApps. Security audits and optimization strategies for reliable, efficient contracts.`
    },
    {
      id: 3,
      icon: '/images/crypto-app/w3.svg',
      title: 'Decentralized Application (dApps) Development',
      text: `Specialize in dApp development, including gaming, blockchain-based apps, wallet development, and DeFi applications.`
    },
    {
      id: 4,
      icon: '/images/crypto-app/w4.svg',
      title: 'Blockchain Consultation',
      text: `Expert blockchain consultation for strategic advice, integration, and utilization.`
    },
    {
      id: 5,
      icon: '/images/crypto-app/w5.svg',
      title: 'Tokenization Services',
      text: `Tokenization services: design, governance, launch, and exchange listing.`
    },
    {
      id: 6,
      icon: '/images/crypto-app/w6.svg',
      title: 'Non-Fungible Tokens (NFT) Solutions',
      text: `Complete NFT solutions: asset tokenization, minting, marketplaces, ticketing, staking, metaverse.`
    }
  ];


  return (
    <main>
      <NextSeo
        title={metaTitle}
        description={metaDesc}
        openGraph={{
          title: `${metaTitle}`,
          description: `${metaDesc}`,
          images: [
            {
              url: `${metaImg}`
            }
          ]
        }}
      />
      <Hero_10 />
      <TrustedPartner />
      <FancyBlock />
      <HomeCard />
      <FeaturesCard
        title={'Alchemis Lab'}
        subTitle={'Your account is secured by private facial biometrics and industry-leading encryption to keep you safe from account takeover attacks and phishing. Discover total control and true peace of mind.'}
        featureList={featuresList} />
      <Testimonial />
      <NeedHelpBlock />
      <CallToActions token={false} />
    </main>
  );
};

export default Home;

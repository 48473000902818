import SingleFeature from "./SingleFeature";
import { SectionHeader } from '@apps/components/section-header';
import React from 'react';

const FeaturesCard = ({title, subTitle, featureList}) => {
  return (
    <section className="relative bg-light-base py-24 dark:bg-jacarta-800">
      <div className="container">
        <SectionHeader title={title} subTitle={subTitle} containerClass={"mx-auto mb-20 text-center"}/>
        <div className="grid gap-7 md:grid-cols-3">
          <SingleFeature featureList={featureList}/>
        </div>
      </div>
    </section>
  );
};

export default FeaturesCard;

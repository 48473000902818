const HelpBlock = () => {
  return (
    <>
      <div className="mt-12 md:flex md:space-x-8 lg:justify-end">
        <div className="relative mb-8 flex-1 self-end rounded-2.5xl bg-[#242A65] p-8 shadow-2xl">
          <div className="absolute right-4 top-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <g clip-path="url(#clip0_172_32846)">
                <path d="M28.9 38L24 45L19.1 38H6C5.46957 38 4.96086 37.7893 4.58579 37.4142C4.21071 37.0391 4 36.5304 4 36V8C4 7.46957 4.21071 6.96086 4.58579 6.58579C4.96086 6.21071 5.46957 6 6 6H42C42.5304 6 43.0391 6.21071 43.4142 6.58579C43.7893 6.96086 44 7.46957 44 8V36C44 36.5304 43.7893 37.0391 43.4142 37.4142C43.0391 37.7893 42.5304 38 42 38H28.9ZM26.818 34H40V10H8V34H21.182L24 38.024L26.818 34Z" fill="white" fill-opacity="0.5"/>
              </g>
              <defs>
                <clipPath id="clip0_172_32846">
                  <rect width="48" height="48" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>
            <span className="mb-2 block font-display text-lg text-white">
              About Alchemis
            </span>
            <p className="mb-4 text-white">
              Meet our team and discover more about us.
            </p>
            <a href="#" className="font-bold text-white underline">
              Learn More
            </a>
          </div>
        </div>
        <div className="relative mb-8 flex-1 self-end rounded-2.5xl bg-primary p-8 py-16 shadow-2xl">
          <div className="absolute right-4 top-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <g clip-path="url(#clip0_172_32843)">
                <path d="M32.0088 18.828L14.7948 36.042L11.9668 33.214L29.1788 16H14.0088V12H36.0088V34H32.0088V18.828Z" fill="white" fill-opacity="0.5"/>
              </g>
              <defs>
                <clipPath id="clip0_172_32843">
                  <rect width="48" height="48" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>
            <span className="mb-2 block font-display text-lg text-white">
              Alchemis Lab
            </span>
            <p className="mb-4 text-white">
              Learn more about the business solutions we provide.
            </p>
            <a href="#" className="font-bold text-white underline">
              Learn More
            </a>
          </div>
        </div>
      </div>
      {/* End ."mt-12 */}

      <div className="relative">
        <div className="relative mx-auto self-start rounded-2.5xl bg-[#1A2641] p-8 shadow-2xl md:max-w-xs">
          <div className="absolute right-4 top-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <g clip-path="url(#clip0_290_37944)">
                <path d="M40 4C41.104 4 42 4.896 42 6V13.514L38 17.514V8H10V40H38V34.484L42 30.484V42C42 43.104 41.104 44 40 44H8C6.896 44 6 43.104 6 42V6C6 4.896 6.896 4 8 4H40ZM43.556 17.616L46.384 20.444L30.828 36L27.996 35.996L28 33.172L43.556 17.616ZM26 24V28H16V24H26ZM32 16V20H16V16H32Z" fill="white" fill-opacity="0.5"/>
              </g>
              <defs>
                <clipPath id="clip0_290_37944">
                  <rect width="48" height="48" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>

          <div>
            <span className="mb-2 block font-display text-lg text-white">
              Blog
            </span>
            <p className="mb-4 text-white">
              Stay up to date with the latest stories and commentary.
            </p>
            <a href="#" className="font-bold text-white underline">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpBlock;
